<template>
  <div class="loginPage">
    <HeadInfo :errorLogin="false" />
    <div class="messageParty d-flex justify-content-center align-items-center">
      <div v-html="errorMsg" style="font-size: 2rem"></div>
    </div>
    <FooterInfo />
  </div>
</template>
<script>
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
export default {
  components: {
    HeadInfo,
    FooterInfo,
  },
  data() {
    return {
      errorMsg: "",
    };
  },
  methods: {
    returnToLogin() {
      this.$router.push({ name: "login" });
    },
  },
  mounted() {
    this.errorMsg = this.$route.query.errMsg;
  },
};
</script>